import React, { useEffect, useState } from 'react';

import ScanParcelsDisplay from './ScanParcelsDisplay';
import { MessageModal } from '../MessageModal';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../styles.css'

const sptGQLURI = 'https://gql-sample-package-tracking-bjudfyf5za-uc.a.run.app/graphql';

const ScanParcels = (props) => {
    const { globalVs } = props;
    const [deliveryTypes, setDeliveryTypes] = useState([
        {
            SampleDeliveryType_ID: '',
            SampleDeliveryType_VC: ''
        }
    ])
    const [sampleDeliveryType, setSampleDeliverType] = useState({
        SampleDeliveryType_ID: '',
        SampleDeliveryType_VC: ''
    });
    const [parcelTrackingNumber, setParcelTrackingNumber] = useState("");
    const [accountNumberBatch, setAccountNumberBatch] = useState("");
    const [stTrackingId, setStTrackingId] = useState("");

    const [showMessageModal, setShowMessageModal] = useState(false)
    const [displayMessage, setDisplayMessage] = useState("");

    const handleUpdateScanParcels = (e) => {
        // e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "prefSampleDeliveryType":
                const sdt = deliveryTypes.find(x => x.SampleDeliveryType_VC === value)
                setSampleDeliverType(sdt)
                break;
            case "prefParcelTrackingNumber":
                setParcelTrackingNumber(value)
                break;
            case "prefAccountNumber":
                setAccountNumberBatch(value)
                break;
            case "prefSTTrackingID":
                setStTrackingId(value)
                break;
            default:
                break;
        }
    }

    const handleCancelScanParcels = (e) => {
        e.preventDefault();
        setSampleDeliverType({
            SampleDeliveryType_ID: '',
            SampleDeliveryType_VC: ''
        })
        setParcelTrackingNumber("")
        setAccountNumberBatch("")
        setStTrackingId("")
    }

    const fetchData = async () => {
        const getSampleDeliveryTypesQuery = `
            { 
                getSampleDeliveryType {
                    SampleDeliveryType_ID
                    SampleDeliveryType_VC
                }
            }`
            ;
        const getSampleDeliveryTypesFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getSampleDeliveryTypesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getSampleDeliveryTypesJSON = await getSampleDeliveryTypesFetch.json();
        if (getSampleDeliveryTypesJSON && getSampleDeliveryTypesJSON.data) {
            // console.log(getSampleDeliveryTypesJSON.data);
            setDeliveryTypes(getSampleDeliveryTypesJSON.data.getSampleDeliveryType)
        }
    }

    const handleScanParcels = async (e) => {
        console.log(sampleDeliveryType);
        const updateTNQuery = `
            {
                updateTrackingNumber(
                    trackingNumVc: ${JSON.stringify(parcelTrackingNumber)}
                    labId: ${JSON.stringify(globalVs.DefaultLab_ID)}
                    accountVc: ${JSON.stringify(accountNumberBatch)}
                    sampleDeliveryTypeId: ${JSON.stringify(sampleDeliveryType.SampleDeliveryType_ID)}
                    samplePackageTrackingId: ${JSON.stringify(stTrackingId)}
                    g_AdminUser_ID: ${JSON.stringify(globalVs.AdminUser_ID)}
                ) 
            }
        `;
        console.log(updateTNQuery);
        const updateTNQueryFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: updateTNQuery }), headers: { 'Content-Type': 'application/json' } });
        const updateTNQueryJSON = await updateTNQueryFetch.json();
        if (updateTNQueryJSON && updateTNQueryJSON.data && updateTNQueryJSON.data.updateTrackingNumber && updateTNQueryJSON.data.updateTrackingNumber === "Tracking Number Updated") {
            setDisplayMessage('Scan Parcels Was Successful')
            setShowMessageModal(true)
            setTimeout(() => {
                setDisplayMessage('')
                setShowMessageModal(false)
                handleCancelScanParcels(e)
            }, 3000)
        } else {
            setDisplayMessage('Could Not Scan Parcels. Please Contact IT')
            setShowMessageModal(true)
            setTimeout(() => {
                setDisplayMessage('')
                setShowMessageModal(false)
                handleCancelScanParcels(e)
            }, 3000)
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line 
    }, [])

    return (
        <>
            <ScanParcelsDisplay
                sampleDeliveryType={sampleDeliveryType}
                parcelTrackingNumber={parcelTrackingNumber}
                accountNumberBatch={accountNumberBatch}
                stTrackingId={stTrackingId}
                handleUpdateScanParcels={handleUpdateScanParcels}
                handleCancelScanParcels={handleCancelScanParcels}
                deliveryTypes={deliveryTypes}
                handleScanParcels={handleScanParcels}
            />
            <MessageModal
                isOpen={showMessageModal}
                setShowEmailModal={setShowMessageModal}
                displayMessage={displayMessage}
            />
        </>
    )
}
export default ScanParcels;