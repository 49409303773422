import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';

import LoginComponent from './LoginComponent';
import { SamplePackageTracking } from './samplePackageTracking';
import ServitechLogoColor from './svg/ServiTechColor.svg'

import './styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { clearEmailToken, clearAccessToken } from './services/User';

const AppWrapper = () => {

    const handleLogOut = async (e) => {
        e.preventDefault()
        clearEmailToken()
        clearAccessToken()
    }

    return (
        <div style={{ background: "rgb(240,242,244", height:"100vh" }}>
            <nav className="navbar ST-navbar fixed-top">
                <div className="container-fluid">
                    <div>
                        {/* eslint-disable-next-line */}
                        <a className="navbar-brand" href="#"><img src={ServitechLogoColor} height="35px" /></a>
                    </div>
                    <div className="service-title">Sample Package Tracking</div>
                    <div className="ps-5 pe-0">
                        {/* eslint-disable-next-line */}
                        <a className="navbar-nav" data-bs-toggle="dropdown">
                            <i className="bi bi-three-dots" style={{ "fontSize": "1.5rem", "color": "#000000", "cursor": "pointer" }}></i>
                        </a>
                        <ul className="me-0 dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item" onClick={(e) => handleLogOut(e)}><Link to="/login" style={{color:"black", textDecoration:"none"}}>Logout</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="content-routes">
                <Routes>
                    <Route path="login" element={<LoginComponent />} />
                    <Route exact path="/" element={<SamplePackageTracking />} />
                </Routes>
            </div>
        </div>
    );
}

export default AppWrapper;
