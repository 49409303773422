import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const fiscalYears = [
    "2023",
    "2021",
    "2022",
    "2024"
];

const EditSampleModal = (props) => {
    const {
        isOpen,
        setModalOpen,
        handleEditSample,
        sampleTypes,
        deliveryTypes,
        labOs,
        sampleToEdit
    } = props;

    const [editedSample, setEditedSample] = useState({
        deliveryId: "",
        trackingNumber: "",
        deliveryType: "",
        fiscalYear: "",
        labNumber: "",
        sampleType: "",
        lab: "",
        scanDate: "",
        printDate: "",
        account: "",
        scannedBy: "",
        updatedBy: "",
        printedBy: "",
        labId: "",
        lastUpdatedById: "",
        sampleDeliveryTypeId: "",
        sampleTypeId: "",
        scanById: ""
    })
    const handleOnHide = () => {
        setModalOpen(!isOpen);
    }

    const handleSampleEdits = (e) => {
        e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "prefLabNumber":
                setEditedSample({ ...editedSample, labNumber: value })
                break;
            case "prefSampleType":
                const st = sampleTypes.find(x => x.SampleType_VC === value);
                setEditedSample({ ...editedSample, sampleType: value, sampleTypeId: st.SampleType_ID })
                break;
            case "prePostalTrackingNumber":
                setEditedSample({ ...editedSample, trackingNumber: value })
                break;
            case "prefDeliveryType":
                const dt = deliveryTypes.find(x => x.SampleDeliveryType_VC === value);
                setEditedSample({ ...editedSample, deliveryType: value, sampleDeliveryTypeId: dt.SampleDeliveryType_ID})
                break;
            case "prefAccount":
                setEditedSample({ ...editedSample, account: value })
                break;
            case "prefLab":
                const l = labOs.find(x => x.Lab_VC === value);
                setEditedSample({ ...editedSample, lab: value, labId: l.Lab_ID})
                break;
            case "prefFiscalYear":
                setEditedSample({ ...editedSample, fiscalYear: value })
                break;
            default:
                break;
        }
    }

    const handleEdit = (e) => {
        e.preventDefault()
        handleEditSample(e, editedSample);
    }


    useEffect(() => {
        if (sampleToEdit && sampleToEdit.trackingNumber !== "") {
            setEditedSample(sampleToEdit)
        }
    }, [isOpen, sampleToEdit])

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <Modal.Header>
                <Modal.Title>
                    Edit Sample Package Tracking
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="row" style={{ marginBottom: "10px" }}>
                        <span className="form-label ">ST SPT ID: {editedSample.deliveryId}</span>
                    </div>

                    <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-sm-6">
                            <div className='form-group'>
                                <label htmlFor="prefLabNumber" className="form-label form-group-label">
                                    Lab Number:
                                </label>
                                <input type="text" className="form-control " id="prefLabNumber" value={editedSample.labNumber} onChange={(e) => handleSampleEdits(e)}/>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='form-group'>
                                <label htmlFor="prefSampleType" className="form-label form-group-label" >
                                    Sample Type:
                                </label>
                                <select className="form-select " id="prefSampleType" value={editedSample.sampleType} onChange={(e) => handleSampleEdits(e)}>
                                    {sampleTypes.map((state, index) =>
                                        <option key={index} value={state.SampleType_VC}>{state.SampleType_VC}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-sm-12 form-group">
                            <label htmlFor="prePostalTrackingNumber" className="form-label form-group-label">
                                Postal Tracking Number:
                            </label>
                            <input type="text" className="form-control " id="prePostalTrackingNumber" value={editedSample.trackingNumber} onChange={(e) => handleSampleEdits(e)}/>
                        </div>
                    </div>

                    <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-sm-6">
                            <div className='form-group'>
                                <label htmlFor="prefDeliveryType" className="form-label form-group-label">
                                    Delivery Type:
                                </label>
                                <select className="form-select " id="prefDeliveryType" value={editedSample.deliveryType} onChange={(e) => handleSampleEdits(e)}>
                                    {deliveryTypes.map((state, index) =>
                                        <option key={index} value={state.SampleDeliveryType_VC}>{state.SampleDeliveryType_VC}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='form-group'>
                                <span className="form-label form-group-label">Scan Date: </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4">
                            <div className='form-group'>
                                <label htmlFor="prefAccount" className="form-label form-group-label">
                                    Account:
                                </label>
                                <input type="text" className="form-control " id="prefAccount" value={editedSample.account} onChange={(e) => handleSampleEdits(e)}/>
                            </div>
                        </div>
                        <div className="col-sm-4" style={{ paddingLeft: "0" }}>
                            <div className='form-group'>
                                <label htmlFor="prefLab" className="form-label form-group-label" >
                                    Lab:
                                </label>
                                <select className="form-select " id="prefLab" value={editedSample.lab} onChange={(e) => handleSampleEdits(e)}>
                                    {labOs.map((state, index) =>
                                        <option key={index} value={state.Lab_VC}>{state.Lab_VC}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-4" style={{ paddingLeft: "0" }}>
                            <div className='form-group'>
                                <label htmlFor="prefFiscalYear" className="form-label form-group-label" >
                                    Fiscal Year:
                                </label>
                                <select className="form-select " id="prefFiscalYear" value={editedSample.fiscalYear} onChange={(e) => handleSampleEdits(e)}>
                                    {fiscalYears.map((state, index) =>
                                        <option key={index} value={state}>{state}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="md" style={{ width: "115px" }} onClick={(e) => { handleEdit(e) }}>Edit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="light" size="md" style={{ width: "115px" }} onClick={(e) => handleOnHide(e)}>Cancel</Button>
            </Modal.Footer>

        </Modal>
    )
};

export default EditSampleModal;