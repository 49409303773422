import React, { useRef, useState } from 'react'

import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function ScanParcelsDisplay(props) {
    const {
        sampleDeliveryType,
        parcelTrackingNumber,
        accountNumberBatch,
        stTrackingId,
        handleUpdateScanParcels,
        handleCancelScanParcels,
        deliveryTypes,
        handleScanParcels
    } = props;

    const sampleDeliveryTypeRef = useRef(null);
    const parcelTrackingNumberRef = useRef(null);
    const accountNumberBatchRef = useRef(null);
    const stTrackingIdRef = useRef(null);

    const [timeOutID, setTID] = useState("")

    const handleSwitchInputs = (e, ref) => {
        clearTimeout(timeOutID)
        // Automatically focus on the next input field
        if (e.nativeEvent.key === 'Enter' || e.type === "change") {
            switchInput(ref)
        } else {
            const id = setTimeout(() => {
                switchInput(ref)
            }, 2500); 
            setTID(id)
        }
    };

    const switchInput = (ref) => {
        switch (ref) {
            case sampleDeliveryTypeRef:
                parcelTrackingNumberRef.current.focus();
                break;
            case parcelTrackingNumberRef:
                accountNumberBatchRef.current.focus();
                break;
            case accountNumberBatchRef:
                stTrackingIdRef.current.focus();
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="container-fluid px-2" style={{ "marginTop": "5px" }}>
                <div className="card mx-auto" >
                    <div className="card-body" >
                        <div className="service-heading mb-2" style={{ "marginTop": "-20px" }}>
                            Scan Parcels
                        </div>
                        <hr style={{ "marginTop": "-20px" }} />
                        <div>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <label htmlFor="prefSampleDeliveryType" className="form-label service-form-label">
                                        Sample Delivery Type
                                    </label>
                                </div>
                                <div className="col-sm-8" >
                                    <select
                                        className="form-select service-form-control"
                                        id="prefSampleDeliveryType"
                                        value={sampleDeliveryType.SampleDeliveryType_VC}
                                        onChange={(e) => { handleUpdateScanParcels(e); handleSwitchInputs(e, sampleDeliveryTypeRef) }}
                                        onKeyDown={(e) => { handleSwitchInputs(e, sampleDeliveryTypeRef) }}
                                        ref={sampleDeliveryTypeRef}
                                    >
                                        <option value="" disabled>--Please Select A Delivery Type---</option>
                                        {deliveryTypes.map((state, index) =>
                                            <option key={index} value={state.SampleDeliveryType_VC}>{state.SampleDeliveryType_VC}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <label htmlFor="prefParcelTrackingNumber" className="form-label service-form-label">
                                        Parcel Tracking Number
                                    </label>
                                </div>
                                <div className="col-sm-8" >
                                    <input
                                        type="text"
                                        className="form-control service-form-control"
                                        id="prefParcelTrackingNumber"
                                        value={parcelTrackingNumber}
                                        onChange={(e) => { handleUpdateScanParcels(e) }}
                                        onKeyDown={(e) => { handleSwitchInputs(e, parcelTrackingNumberRef) }}
                                        ref={parcelTrackingNumberRef}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <label htmlFor="prefAccountNumber" className="form-label service-form-label">
                                        Account Number (Batch)
                                    </label>
                                </div>
                                <div className="col-sm-8" >
                                    <input
                                        type="text"
                                        className="form-control service-form-control"
                                        id="prefAccountNumber"
                                        value={accountNumberBatch}
                                        onChange={(e) => { handleUpdateScanParcels(e) }}
                                        onKeyDown={(e) => { handleSwitchInputs(e, accountNumberBatchRef) }}
                                        ref={accountNumberBatchRef}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <label htmlFor="prefSTTrackingID" className="form-label service-form-label">
                                        ST Sample Package Tracking ID
                                    </label>
                                </div>
                                <div className="col-sm-8" >
                                    <input
                                        type="text"
                                        className="form-control service-form-control"
                                        id="prefSTTrackingID"
                                        value={stTrackingId}
                                        onChange={(e) => { handleUpdateScanParcels(e) }}
                                        onKeyDown={(e) => { handleSwitchInputs(e, stTrackingIdRef) }}
                                        ref={stTrackingIdRef}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <button type="button" className="button-max-primary" onClick={(e) => handleScanParcels(e)}>OK</button>
                                </div>
                                <div className="col-sm-6">
                                    <button type="button" className="button-max-secondary" onClick={(e) => handleCancelScanParcels(e)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


