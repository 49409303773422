import React, { useEffect, useState } from 'react'

import EditSampleModal from './editSampleModal';

import '../../../styles.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const fiscalYears = [
    "2021",
    "2022",
    "2023",
    "2024"
];

export default function ManageDetailsDisplay(props) {
    const {
        servitechTrackingId,
        labNumber,
        sampleType,
        lab,
        details,
        handleUpdateManageDetails,
        handleSelectUnSelectSamples,
        handleCheckItem,
        handleUnCheckItem,
        handleDeleteItem,
        sampleTypeOs,
        labOs,
        deliveryOptions,
        selectedSamples,
        sampleToEdit,
        handleEditSample,
        editModal,
        setEditModal,
        fiscalYear
    } = props;
    const [labs, setlabOptions] = useState([{
        "Lab_ID": "",
        "Lab_VC": ""
    }])
    const [sampleTypes, setSampleTypes] = useState([{
        "SampleType_ID": "",
        "SampleType_VC": ""
    }])
    
    const [deliveryTypes, setDeliveryTypes] = useState([
        {
            SampleDeliveryType_ID: '',
            SampleDeliveryType_VC: ''
        }
    ])

    const isChecked = (id) => {
        return (selectedSamples.indexOf(id) > -1)
    }

    

    const handleOpenModal = () => {
        setEditModal(true)
    }


    useEffect(() => {
        if (sampleTypeOs.length && labOs.length) {
            setlabOptions(labOs)
            setSampleTypes(sampleTypeOs)
        }
        if (deliveryOptions[0].SampleDeliveryType_ID !== '') {
            setDeliveryTypes(deliveryOptions)
        }
    }, [sampleTypeOs, labOs, deliveryOptions])
    return (
        <>
            <div className="container-fluid px-2" style={{ "marginTop": "5px" }}>
                <div className="card mx-auto">
                    <div className="card-body">
                        <div className="service-heading mb-3" style={{ "marginTop": "-20px" }}>
                            Manage Details
                        </div>
                        <hr style={{ "marginTop": "-20px" }} />
                        <div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label htmlFor="prefServitechTrackingID" className="form-label service-form-label2" style={{ marginBottom: "-10px" }}>
                                        Servitech Sample Package Tracking ID:
                                    </label>
                                    <input type="text" className="form-control service-form-control" id="prefServitechTrackingID" value={servitechTrackingId} onChange={(e) => handleUpdateManageDetails(e)} />
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-sm-2">
                                    <label htmlFor="prefLabNumber" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                                        Lab Number:
                                    </label>
                                    <input type="text" className="form-control service-form-control" id="prefLabNumber" value={labNumber} onChange={(e) => handleUpdateManageDetails(e)} />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="prefSampleType" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                                        Sample Type:
                                    </label>
                                    <select className="form-select service-form-control" id="prefSampleType" value={sampleType.SampleType_VC} onChange={(e) => handleUpdateManageDetails(e)}>
                                        <option value="" disabled>--Select Sample Type--</option>
                                        {sampleTypes.map((state, index) =>
                                            <option key={index} value={state.SampleType_VC}>{state.SampleType_VC}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-3">
                                    <label htmlFor="prefLab" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                                        Lab:
                                    </label>
                                    <select className="form-select service-form-control" id="prefLab" value={lab.Lab_VC} onChange={(e) => handleUpdateManageDetails(e)}>
                                        <option value="" disabled>--Select Lab--</option>
                                        {labs.map((state, index) =>
                                            <option key={index} value={state.Lab_VC}>{state.Lab_VC}</option>
                                        )}
                                    </select>
                                </div>
                                <div className="col-sm-3">
                                    <label htmlFor="prefFiscalYear" className="form-label service-form-label2" style={{ marginBottom: "-16px" }}>
                                        Lab:
                                    </label>
                                    <select className="form-select service-form-control" id="prefFiscalYear" value={fiscalYear} onChange={(e) => handleUpdateManageDetails(e)}>
                                        <option value="" disabled>--Select FY--</option>
                                        {fiscalYears.map((state, index) =>
                                            <option key={index} value={state}>{state}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='details-box'>
                                <table className="table">
                                    <thead style={{ position: "sticky", top: "0px", background: "white", borderBottom: "1px solid black", zIndex: 1 }}>
                                        <tr>
                                            <th className='table-detail'>Select</th>
                                            <th className='table-detail'>Delivery ID</th>
                                            <th className='table-detail'>Tracking Number</th>
                                            <th className='table-detail'>Delivery Type</th>
                                            <th className='table-detail'>Fiscal Year</th>
                                            <th className='table-detail'>Lab Number</th>
                                            <th className='table-detail'>Sample Type</th>
                                            <th className='table-detail'>Lab</th>
                                            <th className='table-detail'>Scan Date</th>
                                            <th className='table-detail'>Print Date</th>
                                            <th className='table-detail'>Account</th>
                                            <th className='table-detail'>Scanned By</th>
                                            <th className='table-detail'>Updated By</th>
                                            <th className='table-detail'>Printed By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details && details[0].trackingNumber !== "" && details.map((x, index) =>
                                            <tr key={index}>
                                                <th className='table-detail checkbox-col'>
                                                    <input className="form-check-input" type="checkbox" name="inlineCheckBoxTreatmentRefusal" id="selectedSample" checked={isChecked(x)} value={isChecked(x) ? 'true' : 'false'} onChange={() => handleSelectUnSelectSamples(x)} />
                                                </th>
                                                <th className='table-detail'>
                                                    {x.deliveryId}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.trackingNumber}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.deliveryType}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.fiscalYear}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.labNumber}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.sampleType}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.lab}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.scanDate}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.printDate}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.account}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.scannedBy}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.updatedBy}
                                                </th>
                                                <th className='table-detail'>
                                                    {x.printedBy}
                                                </th>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <button type="button" className="button-max-secondary" onClick={(e) => handleCheckItem(e)}>Check All</button>
                                </div>
                                <div className="col-sm-2">
                                    <button type="button" className="button-max-secondary" onClick={(e) => handleUnCheckItem(e)}>Uncheck All</button>
                                </div>
                                {selectedSamples && selectedSamples.length >= 1 &&
                                    <div className="col-sm-2">
                                        <button type="button" className="button-max-secondary" onClick={(e) => handleDeleteItem(e)}>Delete</button>
                                    </div>
                                }
                                {selectedSamples && selectedSamples.length === 1 &&
                                    <div className="col-sm-2">
                                        <button type="button" className="button-max-secondary" onClick={(e) => handleOpenModal(e)}>Edit</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <EditSampleModal
                            isOpen={editModal}
                            setModalOpen={setEditModal}
                            handleEditSample={handleEditSample}
                            sampleTypes={sampleTypes}
                            deliveryTypes={deliveryTypes}
                            labOs={labOs}
                            sampleToEdit={sampleToEdit[0]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}


