import React, { useEffect, useState } from "react";
import ManageDetails from "./ManageDetails";
import ScanLabNumbers from "./ScanLabNumbers";
import ScanParcels from "./ScanParcels";
import './sampleTrackingStyle.css';
import { Navigate } from "react-router-dom";
import { getAccessToken, getEmail } from '../../services/User';

const tabs = [
    {
        key: 'scanParcels',
        label: 'Scan Parcels'
    },
    {
        key: 'scanLabNumbers',
        label: 'Scan Lab Numbers'
    },
    {
        key: 'manageDetails',
        label: 'Manage Details'
    }
];

const sptGQLURI = 'https://gql-sample-package-tracking-bjudfyf5za-uc.a.run.app/graphql';

const SamplePackageTracking = () => {
    const [activeTab, setActiveTab] = useState('scanParcels');
    const token = getAccessToken();
    const userEmail = getEmail();
    const [globalVs, setGlobalVs] = useState({
        AdminUser_ID: "",
        DefaultLab_ID: "",
        UserLab: ""
    })
    const [labs, setlabOptions] = useState([{
        "Lab_ID": "",
        "Lab_VC": ""
    }])
    const [sampleTypes, setSampleTypes] = useState([{
        "SampleType_ID": "",
        "SampleType_VC": ""
    }])

    async function getGlobalVData() {
        const getUserGlobalDataQuery = `
            {
                getUserGlobalData(email: ${JSON.stringify(userEmail)}) {
                    AdminUser_ID
                    DefaultLab_ID
                }
            }
        `;
        const getUserGlobalDataFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getUserGlobalDataQuery }), headers: { 'Content-Type': 'application/json' } });
        const getUserGlobalDataJSON = await getUserGlobalDataFetch.json();
        // console.log(getUserGlobalDataJSON);
        if (getUserGlobalDataJSON && getUserGlobalDataJSON.data) {
            let lab;
            if (getUserGlobalDataJSON.data.getUserGlobalData.DefaultLab_ID === '1') {
                lab = "Dodge City"
            } else if (getUserGlobalDataJSON.data.getUserGlobalData.DefaultLab_ID === '2') {
                lab = "Hastings"
            } else if (getUserGlobalDataJSON.data.getUserGlobalData.DefaultLab_ID === '3') {
                lab = "Amarillo"
            }
            setGlobalVs({
                AdminUser_ID: getUserGlobalDataJSON.data.getUserGlobalData.AdminUser_ID,
                DefaultLab_ID: getUserGlobalDataJSON.data.getUserGlobalData.DefaultLab_ID,
                UserLab : lab
            })

        }

        const getLabsQuery = `
            {
                getLabs {
                    Lab_ID
                    Lab_VC
                }
            }
        `;
        const getLabsFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getLabsQuery }), headers: { 'Content-Type': 'application/json' } });
        const getLabsJSON = await getLabsFetch.json();
        if (getLabsJSON && getLabsJSON.data) {
            setlabOptions(getLabsJSON.data.getLabs)
        }
        setSampleTypes([
            {
                "SampleType_VC": "Environmental",
                "SampleType_ID": "12"
            },
            {
                "SampleType_VC": "Feeds",
                "SampleType_ID": "3"
            },
            {
                "SampleType_VC": "Fertilizer",
                "SampleType_ID": "4"
            },
            {
                "SampleType_VC": "Plants",
                "SampleType_ID": "7"
            },
            {
                "SampleType_VC": "Soil",
                "SampleType_ID": "8"
            }
        ])
    }

    useEffect(() => {
        getGlobalVData()
        // eslint-disable-next-line
    }, [])

    if (token === null || token === '') {
        return <Navigate to='/login' />
    }
    return (
        <div>
            <div className="action-pills">
                {tabs.map(({ key, label }) => (
                    <div className="pill"
                        aria-hidden="true"
                        key={key}
                        onClick={() => setActiveTab(key)}
                        {...activeTab === key && { className: 'selected' }}
                    >
                        {label}
                    </div>
                ))}
            </div>
            {activeTab === 'manageDetails' && (
                <ManageDetails
                    globalVs={globalVs}
                    labs={labs}
                    sampleTypes={sampleTypes}

                />
            )}
            {activeTab === 'scanLabNumbers' && (
                <ScanLabNumbers
                    globalVs={globalVs}
                    labs={labs}
                    sampleTypes={sampleTypes}
                />
            )}
            {activeTab === 'scanParcels' &&
                <ScanParcels
                    globalVs={globalVs}
                />}
        </div>
    )
}

export default SamplePackageTracking;