import { Modal } from 'react-bootstrap';

const MessageModal = (props) => {
    const {
        isOpen,
        setShowEmailModal,
        displayMessage
    } = props;

    const handleOnHide = () => {
        setShowEmailModal(!isOpen)
    }

    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isOpen}
            onHide={handleOnHide}
        >
            <div className='missing-data-modal'>
                <Modal.Body className='missing-data-modal-body'>
                    <form style={{textAlign: "center"}}>
                        {displayMessage}
                    </form>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default MessageModal;