import React, { useState, useEffect } from 'react';

import ManageDetailsDisplay from './ManageDetailsDisplay';
import { MessageModal } from '../MessageModal';

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../styles.css'

const sptGQLURI = 'https://gql-sample-package-tracking-bjudfyf5za-uc.a.run.app/graphql';


const ManageDetails = (props) => {
    const {
        globalVs,
        labs,
        sampleTypes
    } = props;
    const [servitechTrackingId, setServitechTrackingId] = useState("");
    const [labNumber, setLabNumber] = useState("");
    const [sampleType, setSampleType] = useState({
        "SampleType_ID": "",
        "SampleType_VC": ""
    });
    const [lab, setLab] = useState({
        "Lab_ID": "",
        "Lab_VC": ""
    });
    const [fiscalYear, setFiscalYear] = useState("");
    const [details, setDetails] = useState([{
        deliveryId: "",
        trackingNumber: "",
        deliveryType: "",
        fiscalYear: "",
        labNumber: "",
        sampleType: "",
        lab: "",
        scanDate: "",
        printDate: "",
        account: "",
        scannedBy: "",
        updatedBy: "",
        printedBy: "",
        labId: "",
        lastUpdatedById: "",
        sampleDeliveryTypeId: "",
        sampleTypeId: "",
        scanById: ""
    }])
    const [selectedSamples, setSelectedSamples] = useState([]);
    const [deliveryTypes, setDeliveryTypes] = useState([
        {
            SampleDeliveryType_ID: '',
            SampleDeliveryType_VC: ''
        }
    ])
    const [sampleToEdit, setSampleToEdit] = useState('');
    const [editModal, setEditModal] = useState(false)

    const [showMessageModal, setShowMessageModal] = useState(false)
    const [displayMessage, setDisplayMessage] = useState("");

    async function fetchData() {
        const getSampleDeliveryTypesQuery = `
            { 
                getSampleDeliveryType {
                    SampleDeliveryType_ID
                    SampleDeliveryType_VC
                }
            }`
            ;
        const getSampleDeliveryTypesFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getSampleDeliveryTypesQuery }), headers: { 'Content-Type': 'application/json' } });
        const getSampleDeliveryTypesJSON = await getSampleDeliveryTypesFetch.json();
        if (getSampleDeliveryTypesJSON && getSampleDeliveryTypesJSON.data) {
            // console.log(getSampleDeliveryTypesJSON.data);
            setDeliveryTypes(getSampleDeliveryTypesJSON.data.getSampleDeliveryType)
        }
    }

    const handleUpdateManageDetails = (e) => {
        e.preventDefault();
        const value = e.target.value;
        switch (e.target.id) {
            case "prefServitechTrackingID":
                setServitechTrackingId(value)
                break;
            case "prefLabNumber":
                setLabNumber(value)
                break;
            case "prefSampleType":
                const st = sampleTypes.find(x => x.SampleType_VC === value);
                setSampleType(st)
                break;
            case "prefLab":
                const l = labs.find(x => x.Lab_VC === value);
                setLab(l)
                break;
            case "prefFiscalYear":
                setFiscalYear(value)
                break;
            default:
                break;
        }
    }

    const handleSelectUnSelectSamples = (sample) => {
        if (selectedSamples.includes(sample)) {
            // Sample is already selected, unselect it
            setSelectedSamples((prevSelectedSamples) => prevSelectedSamples.filter((x) => x !== sample));
        } else {
            // Sample is not selected, select it
            setSelectedSamples((prevSelectedSamples) => [...prevSelectedSamples, sample]);
        }
    };

    async function getGridDataBySampleFunction() {
        let getDataToFillGridQuery = `{ GetDataToFillGrid(`
        if (labNumber !== "") {
            getDataToFillGridQuery += ` searchLnTxt:${JSON.stringify(labNumber)}`
        }
        if (sampleType.SampleType_ID !== "") {
            getDataToFillGridQuery += ` selectedSearchSt:${JSON.stringify(sampleType.SampleType_ID)}`
        }
        if (lab.Lab_ID !== "") {
            getDataToFillGridQuery += ` selectedSearchLab:${JSON.stringify(lab.Lab_ID)}`
        }
        if (fiscalYear !== "") {
            getDataToFillGridQuery += ` fiscalYearId:${JSON.stringify(fiscalYear)}`
        }

        getDataToFillGridQuery += `
            ) {
                Account_VC
                FiscalYear_ID
                Lab_ID
                LabNumber_IN
                LastUpdatedBy_ID
                Print_DT
                PrintedBy
                SampleDeliveryType_ID
                SamplePackageTracking_ID
                SampleType_ID
                Scan_DT
                ScanBy_ID
                ScannedBy
                TrackingNumber_VC
                UpdatedBy
                SampleDeliveryType_VC
                Lab_VC
            }
        }`;

        // console.log(getDataToFillGridQuery);
        const getDataToFillGridFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getDataToFillGridQuery }), headers: { 'Content-Type': 'application/json' } });
        const getDataToFillGridJson = await getDataToFillGridFetch.json();
        if (getDataToFillGridJson && getDataToFillGridJson.data && getDataToFillGridJson.data.GetDataToFillGrid.length) {
            let resultsArray = [];
            getDataToFillGridJson.data.GetDataToFillGrid.forEach((x) => {
                let sSampleType = '';
                if (x.SampleType_ID !== "" && x.SampleType_ID !== null && x.SampleType_ID !== undefined) {
                    const newV = sampleTypes.find(sSample => parseInt(sSample.SampleType_ID) === x.SampleType_ID)
                    sSampleType = newV.SampleType_VC;
                }
                const Account_VC = x.Account_VC || "";
                const FiscalYear_ID = x.FiscalYear_ID || "";
                const LabNumber_IN = x.LabNumber_IN || "";
                const Lab_ID = x.Lab_ID || "";
                const LastUpdatedBy_ID = x.LastUpdatedBy_ID || "";
                const Print_DT = x.Print_DT || "";
                const PrintedBy = x.PrintedBy || "";
                const SampleDeliveryType_ID = x.SampleDeliveryType_ID || "";
                const SamplePackageTracking_ID = x.SamplePackageTracking_ID || "";
                const SampleType_ID = x.SampleType_ID || "";
                const ScanBy_ID = x.ScanBy_ID || "";
                const Scan_DT = x.Scan_DT || "";
                const ScannedBy = x.ScannedBy || "";
                const TrackingNumber_VC = x.TrackingNumber_VC || "";
                const UpdatedBy = x.UpdatedBy || "";
                const SampleDeliveryType_VC = x.SampleDeliveryType_VC || "";
                const RLab_VC = x.Lab_VC || "";
                resultsArray.push({
                    deliveryId: SamplePackageTracking_ID,
                    trackingNumber: TrackingNumber_VC,
                    deliveryType: SampleDeliveryType_VC,
                    fiscalYear: FiscalYear_ID,
                    labNumber: LabNumber_IN,
                    sampleType: sSampleType,
                    lab: RLab_VC,
                    scanDate: Scan_DT,
                    printDate: Print_DT,
                    account: Account_VC,
                    scannedBy: ScannedBy,
                    updatedBy: UpdatedBy,
                    printedBy: PrintedBy,
                    labId: Lab_ID,
                    lastUpdatedById: LastUpdatedBy_ID,
                    sampleDeliveryTypeId: SampleDeliveryType_ID,
                    sampleTypeId: SampleType_ID,
                    scanById: ScanBy_ID
                })
            })
            setDetails(resultsArray)
        }
    }

    async function getGridDataByTNFunction() {
        const getSamplePackageTrackingByIdQuery = `
            {
                getSamplePackageTrackingById(id:${JSON.stringify(servitechTrackingId)}){
                    Account_VC
                    FiscalYear_ID
                    Lab_ID
                    LabNumber_IN
                    LastUpdatedBy_ID
                    Print_DT
                    PrintedBy
                    SampleDeliveryType_ID
                    SamplePackageTracking_ID
                    SampleType_ID
                    Scan_DT
                    ScanBy_ID
                    ScannedBy
                    TrackingNumber_VC
                    UpdatedBy
                    SampleDeliveryType_VC
                    Lab_VC
                }
            }
        `;

        // console.log(getSamplePackageTrackingByIdQuery);
        const getSamplePackageTrackingByIdFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: getSamplePackageTrackingByIdQuery }), headers: { 'Content-Type': 'application/json' } });
        const getSamplePackageTrackingByIdJSON = await getSamplePackageTrackingByIdFetch.json();
        if (getSamplePackageTrackingByIdJSON && getSamplePackageTrackingByIdJSON.data && getSamplePackageTrackingByIdJSON.data.getSamplePackageTrackingById.length) {
            let resultsArray = [];
            getSamplePackageTrackingByIdJSON.data.getSamplePackageTrackingById.forEach((x) => {
                let sSampleType = '';
                if (x.SampleType_ID !== "" && x.SampleType_ID !== null && x.SampleType_ID !== undefined) {
                    const newV = sampleTypes.find(sSample => parseInt(sSample.SampleType_ID) === x.SampleType_ID)
                    sSampleType = newV.SampleType_VC;
                }
                const Account_VC = x.Account_VC || "";
                const FiscalYear_ID = x.FiscalYear_ID || "";
                const LabNumber_IN = x.LabNumber_IN || "";
                const Lab_ID = x.Lab_ID || "";
                const LastUpdatedBy_ID = x.LastUpdatedBy_ID || "";
                const Print_DT = x.Print_DT || "";
                const PrintedBy = x.PrintedBy || "";
                const SampleDeliveryType_ID = x.SampleDeliveryType_ID || "";
                const SamplePackageTracking_ID = x.SamplePackageTracking_ID || "";
                const SampleType_ID = x.SampleType_ID || "";
                const ScanBy_ID = x.ScanBy_ID || "";
                const Scan_DT = x.Scan_DT || "";
                const ScannedBy = x.ScannedBy || "";
                const TrackingNumber_VC = x.TrackingNumber_VC || "";
                const UpdatedBy = x.UpdatedBy || "";
                const SampleDeliveryType_VC = x.SampleDeliveryType_VC || "";
                const RLab_VC = x.Lab_VC || "";
                resultsArray.push({
                    deliveryId: SamplePackageTracking_ID,
                    trackingNumber: TrackingNumber_VC,
                    deliveryType: SampleDeliveryType_VC,
                    fiscalYear: FiscalYear_ID,
                    labNumber: LabNumber_IN,
                    sampleType: sSampleType,
                    lab: RLab_VC,
                    scanDate: Scan_DT,
                    printDate: Print_DT,
                    account: Account_VC,
                    scannedBy: ScannedBy,
                    updatedBy: UpdatedBy,
                    printedBy: PrintedBy,
                    labId: Lab_ID,
                    lastUpdatedById: LastUpdatedBy_ID,
                    sampleDeliveryTypeId: SampleDeliveryType_ID,
                    sampleTypeId: SampleType_ID,
                    scanById: ScanBy_ID
                })
            })
            setDetails(resultsArray)
        }
    }

    const handleCheckItem = () => {
        const allSamples = details.map((x) => x);
        setSelectedSamples(allSamples);
    };

    const handleUnCheckItem = () => {
        setSelectedSamples([]);
    };

    const handleEditSample = async (e, ste) => {
        e.preventDefault();
        const updateSamplePackageTrackingQuery = `
            {
                updateSamplePackageTracking(
                    trackingNumVc:${JSON.stringify(ste.trackingNumber)},
                    sampleDeliverytypeId:${JSON.stringify(ste.deliveryId)},
                    fiscalYearId:${JSON.stringify(JSON.stringify(ste.fiscalYear))},
                    labNumberIn:${JSON.stringify(JSON.stringify(ste.labNumber))},
                    accountVc:${JSON.stringify(ste.account)},
                    sampleTypeId:${JSON.stringify(JSON.stringify(ste.sampleTypeId))},
                    labId:${JSON.stringify(JSON.stringify(ste.labId))},
                    g_AdminUser_ID:${JSON.stringify(globalVs.AdminUser_ID)},
                    samplePackageTrackingId:${JSON.stringify(ste.deliveryId)}
                )
            }
        `;
        const updateSamplePackageTrackingFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: updateSamplePackageTrackingQuery }), headers: { 'Content-Type': 'application/json' } });
        const result = await updateSamplePackageTrackingFetch.json();
        if (result && result.data && result.data.updateSamplePackageTracking && result.data.updateSamplePackageTracking === 'Sample Updated') {
            setEditModal(false)
            setDisplayMessage("Edit Was Successful")
            setShowMessageModal(true)
            setTimeout(() => {
                setShowMessageModal(false)
                setDisplayMessage("")
                getGridDataBySampleFunction()
            }, 3000)
        } else {
            setEditModal(false)
            setDisplayMessage("Could Not Edit. Please Contact IT.")
            setShowMessageModal(true)
            setTimeout(() => {
                setShowMessageModal(false)
                setDisplayMessage("")
                getGridDataBySampleFunction()
            }, 3000)
        }
    }

    const handleDeleteItem = async (e) => {
        //todo
        for (let index = 0; index < selectedSamples.length; index++) {
            const element = selectedSamples[index];
            console.log(element.deliveryId);
            const deleteSamplePackageTrackingQuery = `
            {
                deleteSamplePackageTracking(
                    samplePackageTrackingId:${JSON.stringify(element.deliveryId)}
                )
            }
        `;
            const deleteSamplePackageTrackingFetch = await fetch(sptGQLURI, { method: 'POST', body: JSON.stringify({ query: deleteSamplePackageTrackingQuery }), headers: { 'Content-Type': 'application/json' } });
            await deleteSamplePackageTrackingFetch.json();
        }
        setDisplayMessage("The Selected Sample(s) have been Deleted")
        setShowMessageModal(true)
        setTimeout(() => {
            setShowMessageModal(false)
            setDisplayMessage("")
            setSelectedSamples([])
            getGridDataBySampleFunction()
        }, 3000)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (labNumber !== "" || sampleType.SampleType_ID !== "" || lab.Lab_ID !== "" || fiscalYear !== "") {
            setServitechTrackingId("")
            getGridDataBySampleFunction()
        }
        // eslint-disable-next-line
    }, [labNumber, sampleType, lab, fiscalYear])

    useEffect(() => {
        if (servitechTrackingId !== "") {
            setLabNumber("")
            setSampleType({
                "SampleType_ID": "",
                "SampleType_VC": ""
            })
            setLab({
                "Lab_ID": "",
                "Lab_VC": ""
            })
            setFiscalYear("")
            getGridDataByTNFunction()
        }
        // eslint-disable-next-line
    }, [servitechTrackingId])

    useEffect(() => {
        if (selectedSamples && selectedSamples.length === 1) {
            setSampleToEdit(selectedSamples)
        } else {
            setSampleToEdit('')
        }
    }, [selectedSamples])

    return (
        <>
            <ManageDetailsDisplay
                servitechTrackingId={servitechTrackingId}
                labNumber={labNumber}
                sampleType={sampleType}
                lab={lab}
                details={details}
                handleUpdateManageDetails={handleUpdateManageDetails}
                handleSelectUnSelectSamples={handleSelectUnSelectSamples}
                handleCheckItem={handleCheckItem}
                handleUnCheckItem={handleUnCheckItem}
                handleDeleteItem={handleDeleteItem}
                sampleTypeOs={sampleTypes}
                labOs={labs}
                deliveryOptions={deliveryTypes}
                selectedSamples={selectedSamples}
                sampleToEdit={sampleToEdit}
                handleEditSample={handleEditSample}
                editModal={editModal}
                setEditModal={setEditModal}
                fiscalYear={fiscalYear}
            />
            <MessageModal
                isOpen={showMessageModal}
                setShowEmailModal={setShowMessageModal}
                displayMessage={displayMessage}
            />
        </>
    )
}
export default ManageDetails;